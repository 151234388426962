/**
 * @module app.config
 */

const AppConfig = {
  dialVerse: () => {
    return process.env.REACT_APP_API_URL
  },
  getUrl: (path) => {
    return `${AppConfig.dialVerse()}${path}`
  },
  env: process.env.REACT_APP_ENV,
}

export default AppConfig