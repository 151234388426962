import './App.css';
import HomePage from './pages/home/HomePage';

function App() {
  return (
    <div className="App">
      <header className="container">
        <HomePage title={'Welcome to DialVerse'}/>
      </header>
    </div>
  );
}

export default App;
