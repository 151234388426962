import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import './home.styles.css'
import UserList from '../../modules/users/UserList'
import { useGetUsersMutation } from '../../services/user.service'

function HomePage({ title = '' }) {
  const [userGetUsers, { isLoading, isError }] = useGetUsersMutation()
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await userGetUsers().unwrap()
        setUsers(response)
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }

    if (!isLoading) fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container">
      <div className="my-5">
        <h1 className="title-text">{title}</h1>
      </div>
      <UserList users={users} isError={isError} isLoading={isLoading} />
    </div>
  )
}

HomePage.propTypes = {
  title: PropTypes.string.isRequired,
}

export default HomePage
