import { Provider } from 'react-redux';
import { store } from '../app/store'

function AppProvider({ children }) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

export default AppProvider;