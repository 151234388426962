/**
 * @module user.service
 */

import { DialVerseApi } from "../app/api";
import AppConfig from "../config";

const UserService = DialVerseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.mutation({
      query: () => ({ 
        url: AppConfig.getUrl("/users/1"),
        method: "GET",
      }),
    }),
    getUsers: builder.mutation({
      query: () => ({ 
        url: AppConfig.getUrl("/users"),
        method: "GET",
      }),
    })
  }),
  overrideExisting: false,
})

export const { useGetUserMutation, useGetUsersMutation } = UserService