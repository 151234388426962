/**
 * @module random.utils
 */

export function getRandomNumber (min, max){
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function getRandomGender () {
  const genders = ['male', 'female']
  return genders[getRandomNumber(0, genders.length - 1)]
}