import PropTypes from "prop-types"
import UserListItem from "./UserListItem"
import { getRandomGender } from "../../utils/random"

function UserList({ users = [], isLoading = false, isError = false }) {
  return (
    <div>
      {!isLoading && !isError ? (
        <div className="flex row flex-row">
          {users.map((user) => (
            <div 
              key={user.id}  
              className="col-md-4 col-sx-12 col-sm-12" >
              <UserListItem 
                user={user} 
                gender={getRandomGender()} 
              />
            </div>
          ))}
        </div>
      ) : isLoading ? (
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="alert alert-warning" role="alert">
          Unable to fetch users. Please try again later.
        </div>
      )}
    </div>
  )
}

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default UserList
