import { configureStore } from '@reduxjs/toolkit'
import UserReducer from '../state/user.slice'
import { DialVerseApi } from './api'
import Reactotron from '../ReactotronConfig'

export const store = configureStore({
  reducer: {
    user: UserReducer,
    [DialVerseApi.reducerPath]: DialVerseApi.reducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(DialVerseApi.middleware),
  ...(process.env.NODE_ENV === 'development' ? {
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(Reactotron.createEnhancer()),
  } : {})
})
