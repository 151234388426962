import { formatAddress } from '../../utils/address'
import './user.styles.css'

function UserListItem({ user = {}, gender}) {
  return (
    <div className="card mb-3" id='user-item-container'>
      <div className="row g-0">
        <div className="col-md-4">
          <img
            src={`https://i.pravatar.cc/300`}
            className="img-fluid rounded-start" 
            alt="..." 
          />
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">{user.name}</h5>
            <p className="card-text">
              {formatAddress(user.address)}
            </p>
            <p className="card-text">
              <small className="text-body-secondary">{user.phone}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserListItem
