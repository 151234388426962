/**
 * @module app.api
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { selectUserToken } from '../state/user.slice'

export const DialVerseApi = createApi({
  reducerPath: 'base',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const state = getState()
      const authToken = selectUserToken(state)
      if (headers?.['map']?.['content-type']) {
        headers.set('Content-Type', headers['map']['content-type'])
      } else {
        headers.set('Content-Type', `application/json`)
      }
      headers.set('Accept', `application/json`)
      headers.set(
        'Authorization',
        authToken ? `Bearer ${authToken}` : undefined
      )
      return headers
    },
  }),
  endpoints: () => ({}),
})
