import Reactotron from "reactotron-react-js"
import { reactotronRedux as reduxPlugin } from "reactotron-redux"

Reactotron.configure({
  name: "DialVerse",
})
  .use(reduxPlugin())
  .connect()

export default Reactotron
