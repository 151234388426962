/**
 * @module address.utils
 */

export function formatAddress(address) {
  const { street, suite, city, zipcode } = address
  
  const formattedAddress = [
    street,
    suite ? suite : '',
    city,
    zipcode
  ]
  .filter(part => part)
  .join(', ')

  return formattedAddress
}